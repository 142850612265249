import filesController from "../../api/controllers/files"

export enum DragEvent {
    Drag = "drag",
    DragEnd = "dragend",
    DragEnter = "dragenter",
    DragLeave = "dragleave",
    DragOver = "dragover",
    DragStart = "dragstart",
    Drop = "drop"
}

const imageType = "image"
const fileType = "file"

enum imageTypes {
    jpeg = ".jpeg",
    jpg = ".jpg",
    png = ".png",
    svg = ".svg"
}

export const specifyAttachmentType = (fileName: string) => {
    for (const type of Object.values(imageTypes)) {
        if (fileName.endsWith(type)) {
            return imageType
        }
    }

    return fileType
}

export const uploadFile = async (file: File): Promise<UploadedFile> => {
    const url = await filesController.uploadFileB64({
        Name: file.name,
        Content: file
    })
    return {
        Url: url,
        ContentType: specifyAttachmentType(file.name),
        Name: file.name,
        Size: file.size
    }
}

export type UploadedFile = {
    Url: string
    ContentType: string
    Name: string
    Size: number
}

export const uploadFiles = async (files: File[]): Promise<UploadedFile[]> => {
    return await Promise.all(
        files.map(async file => {
            return await uploadFile(file)
        })
    )
}

export const getFileExt = (url: string): string => url.slice(url.lastIndexOf(".") + 1, url.length)

export const fileEndings = ["B", "KB", "MB", "GB", "TB"]

// Getting size in bytes
export const getBeautifiedFileSize = (size: number): string => {
    let resultStr = `${size} B`

    for (let i = 0; i < fileEndings.length; i++) {
        const currEndingIndex = fileEndings.length - i - 1
        const currSize = Math.floor(size / 1024 ** currEndingIndex)
        if (currSize > 0) {
            resultStr = `${currSize} ${fileEndings[currEndingIndex]}`
            return resultStr
        }
    }
    return resultStr
}
