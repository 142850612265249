import React, { Dispatch, SetStateAction } from "react"
import { ClassProps } from "../../utility/common/props"
import styles from "./ArticleActionButtons.module.scss"
import IconButton from "../IconButton/IconButton"
import { faPaperPlane, faPlus } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import { testId } from "../../utility/tests/testId"
import cn from "classnames"
import { FillingSlot } from "../../models/slot"
import { SlotsToFillStateProps } from "../Article/Article"
import { useDefaultActions } from "./hooks/useDefaultActions"
import { useAppSelector } from "../../store/store"
import { selectArticleActionButtonsActions } from "../../store/knowledgeBase/selectors"

const tNamespace = "knowledgeBase:article-actions."

export interface ArticleActionButtonsProps extends ClassProps {
    symbolCode: string
    setSlotsToFillState?: Dispatch<SetStateAction<SlotsToFillStateProps>>
    slotsToFill: FillingSlot[]
    isPublished: boolean
}

const ArticleActionButtons: React.FC<ArticleActionButtonsProps> = props => {
    const { t } = useTranslation()
    const defaultActions = useDefaultActions(props)
    const actions = useAppSelector(selectArticleActionButtonsActions)
    const { edit, send } = actions ?? defaultActions

    return (
        <div className={styles.articleActions} data-testid={testId.articleActions}>
            {edit && (
                <IconButton
                    icon={faPlus}
                    className={cn(styles.articleActions_button, styles.articleActions_pasteArticleButton)}
                    onClick={edit.onClick}
                    variant="primary"
                    disabled={edit.disabled}
                >
                    {t(`${tNamespace}edit-article-button`)}
                </IconButton>
            )}
            {send && (
                <IconButton
                    icon={faPaperPlane}
                    className={styles.articleActions_button}
                    onClick={send.onClick}
                    variant="primary"
                    disabled={send.disabled}
                >
                    {t(`${tNamespace}send`)}
                </IconButton>
            )}
        </div>
    )
}

export default ArticleActionButtons
