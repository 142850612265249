import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Socket } from "socket.io-client"
import Peer, { MediaConnection } from "peerjs"
import AsyncState from "../../core/asyncState"
import {
    DialogBadge,
    ISearchOperatorsByCriterionRequest,
    ISearchOperatorsByCriterionResponse
} from "../../models/Dialogs/dialog"
import { GetUpdatedMessagesRequest, MessageAttachment, Sticker, UpdatedMessage } from "../../models/Dialogs/message"
import { SystemError } from "../../core/error"
import { LegacyDialog } from "../../models/Dialogs/legacyDialog"
import { SurveyForm } from "../../models/Dialogs/surveyForm"
import { dialogDraftsLocalStoreDriver, dialogsApi } from "../../api/controllers/dialogs"
import { convertDialogTopicsToTree, getListOfSelectedIds } from "../../utility/dialogs/dialog-topics"
import { IAISuggestItemResponse } from "../../models/Dialogs/aiSuggestDTOs"
import { resetReducerState } from "../action"
import { ChannelTypeString, SimpleChannel, SimpleChannelWithUserId } from "../../models/channel"
import { ContentEditorCommands } from "../../components/ContentEditor/ContentEditor"

export interface DialogTopicsTreeData {
    rootId: string
    items: Record<string, DialogTopicsTreeItem>
}
export interface DialogTopicsTreeItemData {
    isSelected: boolean
    parentId: string
    title: string
}
export interface DialogTopicsTreeItem {
    id: string
    children: string[]
    hasChildren?: boolean
    isExpanded?: boolean
    isChildrenLoading?: boolean
    data: DialogTopicsTreeItemData
}

export interface DialogEmailData {
    subject: string
    attachments: MessageAttachment[]
    emailAnswerMode: EmailAnswerMode
}

export enum EmailAnswerMode {
    None = "",
    Reply = "Reply",
    Forward = "Forward"
}

export interface IAIAssistHint {
    alarm: boolean
    message: string
    supportArticles: Array<Record<string, string>>
}

export type EditMessageData = {
    id: string
    attachments?: MessageAttachment[]
    sticker?: Sticker
    text?: string
}

export interface DialogOutgoingData {
    channel: SimpleChannel
    channelUserId: string
}

export type CallMode = "video-call" | "screensharing"

export interface VideoCallState {
    socketRef?: Socket
    peerRef?: Peer
    clientStreamRef?: MediaStream
    operatorStreamRef?: MediaStream
    callMode?: CallMode
    isOperatorAudioEnabled?: boolean
    isOperatorVideoEnabled?: boolean
    isControlEnabled?: boolean
    currentRoomId?: string
    currentOperatorId?: string
    currentCall?: MediaConnection
    currentClientId?: string
    isCollapsed: boolean
}

export type DialogsSlice = Readonly<{
    aiAssistHint: AsyncState<IAIAssistHint>
    aiSuggest: AsyncState<IAISuggestItemResponse[]>
    aiAssistRequestTypes: AsyncState<string[]>
    operatorDialogs: AsyncState<DialogBadge[]>
    current: AsyncState<LegacyDialog>
    messages: AsyncState<UpdatedMessage[]>
    survey: AsyncState<SurveyForm>
    selectedDialogId?: string
    selectedOperatorClientId?: string
    messagesPaginationState?: GetUpdatedMessagesRequest
    searchCriterion?: string
    searchOperatorByCriterionResponse: ISearchOperatorsByCriterionResponse[]
    searchOperatorByCriterionPrevArgs: ISearchOperatorsByCriterionRequest
    topics: {
        isTopicsSidebarOpened: boolean
        treeData: DialogTopicsTreeData
    }
    /*
      Сейчас у нас целых 5 стейтов для сообщения
      inputMessage, inputDrafted - redux
      dialogDraftsLocalStoreDriver - localStorage
      editorContent -  в DialogMessageInput
      text - в формике в DialogMessageInput
      Также в DialogContentEditor есть странный хак с обновлением стейта
      Все эти стейты взаимосвязаны, делают похожие вещи и влияют друг на друга, что приводит к багам

      TODO: Оставить 2 стейта
      - для сохранения стейта инпута локально при перезагрузке страницы (можно оставить текущий из localStorage)
      - для локального хранения стейта инпута
    */
    inputMessage: string
    inputDrafted: string
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: boolean
        isArticleSidebarOpened: boolean
    }
    fastReplies: {
        isFastRepliesSidebarOpened: boolean
    }
    dialogCreate: {
        creatingDialog: boolean
        isChannelSelectSidebarOpened: boolean
        isCreatingClientSidebarOpened: boolean
        dialogCreateOutgoingData: DialogOutgoingData | null
    }
    searchData: DialogBadge[]
    lastMessageChannel: SimpleChannel
    inputEditMessageData?: EditMessageData
    forceReadonly: boolean
    isInputMaximized: boolean
    RMECommands: ContentEditorCommands | null
    dialogEmailData: DialogEmailData
    currentOmniUserId: string
    videoCallScreenEnabled: boolean
    videoCallState: VideoCallState
}>

const initTopicsTree: DialogTopicsTreeData = {
    rootId: "root",
    items: {
        root: {
            id: "root",
            children: [],
            data: {
                parentId: "",
                title: "",
                isSelected: false
            }
        }
    }
}

const initialState: DialogsSlice = {
    aiAssistHint: AsyncState.create(),
    aiSuggest: AsyncState.create(),
    aiAssistRequestTypes: AsyncState.create(),
    operatorDialogs: AsyncState.create(),
    current: AsyncState.create(),
    messages: AsyncState.create(),
    survey: AsyncState.create(),
    searchOperatorByCriterionPrevArgs: {
        query: "",
        statuses: [],
        queues: [],
        roles: [],
        includeCurrentUser: false
    },
    searchOperatorByCriterionResponse: [],
    topics: {
        isTopicsSidebarOpened: false,
        treeData: initTopicsTree
    },
    inputMessage: "",
    inputDrafted: "",
    knowledgeBase: {
        isKnowledgeBaseSidebarOpened: false,
        isArticleSidebarOpened: false
    },
    fastReplies: {
        isFastRepliesSidebarOpened: false
    },
    dialogCreate: {
        creatingDialog: false,
        isChannelSelectSidebarOpened: false,
        isCreatingClientSidebarOpened: false,
        dialogCreateOutgoingData: null
    },
    searchData: [],
    lastMessageChannel: {
        Id: "",
        Type: ChannelTypeString.Unknown,
        Title: ""
    },
    inputEditMessageData: undefined,
    forceReadonly: false,
    isInputMaximized: false,
    RMECommands: null,
    dialogEmailData: {
        subject: "",
        attachments: [],
        emailAnswerMode: EmailAnswerMode.None
    },
    currentOmniUserId: "",
    videoCallScreenEnabled: false,
    videoCallState: {
        isCollapsed: true
    }
}

const dialogs = createSlice({
    name: "dialogs",
    initialState,
    reducers: {
        getAIAssistHintProcess(state) {
            state.aiAssistHint = state.aiAssistHint.toProcess()
        },
        getAIAssistHintSuccess(state, action: PayloadAction<IAIAssistHint>) {
            state.aiAssistHint = state.aiAssistHint.toSuccess(action.payload)
        },
        getAIAssistHintFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistHint = state.aiAssistHint.toFailed(action.payload)
        },
        getAIAssistHintReset(state) {
            state.aiAssistHint = AsyncState.create()
        },
        getAISuggestProcess(state) {
            state.aiSuggest = state.aiSuggest.toProcess()
        },
        getAISuggestSuccess(state, action: PayloadAction<IAISuggestItemResponse[]>) {
            state.aiSuggest = state.aiSuggest.toSuccess(action.payload)
        },
        getAISuggestFailed(state, action: PayloadAction<SystemError>) {
            state.aiSuggest = state.aiSuggest.toFailed(action.payload)
        },
        getAISuggestReset(state) {
            state.aiSuggest = AsyncState.create()
        },
        getAIAssistRequestTypesProcess(state) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toProcess()
        },
        getAIAssistRequestTypesSuccess(state, action: PayloadAction<string[]>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toSuccess(action.payload)
        },
        getAIAssistRequestTypesFailed(state, action: PayloadAction<SystemError>) {
            state.aiAssistRequestTypes = state.aiAssistRequestTypes.toFailed(action.payload)
        },
        getOperatorDialogsProcess(state) {
            state.operatorDialogs = state.operatorDialogs.toProcess()
        },
        getOperatorDialogsSuccess(state, action: PayloadAction<DialogBadge[]>) {
            state.operatorDialogs = state.operatorDialogs.toSuccess(action.payload)
        },
        getOperatorDialogsFailed(state, action: PayloadAction<SystemError>) {
            state.operatorDialogs = state.operatorDialogs.toFailed(action.payload)
        },
        getCurrentDialogProcess(state) {
            state.current = state.current.toProcess()
        },
        getCurrentDialogSuccess(state, action: PayloadAction<LegacyDialog>) {
            state.current = state.current.toSuccess(action.payload)
        },
        getCurrentDialogFailed(state, action: PayloadAction<SystemError>) {
            state.current = state.current.toFailed(action.payload)
        },
        getDialogMessagesProcess(state) {
            state.messages = state.messages.toProcess()
        },
        getDialogMessagesSuccess(state, action: PayloadAction<UpdatedMessage[]>) {
            state.messages = state.messages.toSuccess(action.payload)
        },
        getDialogMessagesFailed(state, action: PayloadAction<SystemError>) {
            state.messages = state.messages.toFailed(action.payload)
        },
        setCurrentOperatorClientId(state, action: PayloadAction<string>) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
            state.selectedOperatorClientId = action.payload
        },
        unsetCurrentOperatorClientId(state) {
            state.selectedOperatorClientId = undefined
        },
        setCurrentDialogId(state, action: PayloadAction<string>) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
            state.selectedDialogId = action.payload
        },
        unsetCurrentDialogId(state) {
            state.selectedDialogId = undefined
        },
        setSearchCriterion(state, action: PayloadAction<string>) {
            state.searchCriterion = action.payload
        },
        getClientSurveyProcess(state) {
            state.survey = state.survey.toProcess()
        },
        getClientSurveySuccess(state, action: PayloadAction<SurveyForm>) {
            state.survey = state.survey.toSuccess(action.payload)
        },
        getClientSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.survey = state.survey.toFailed(action.payload)
        },
        setMessagesPaginationState(state, action: PayloadAction<Partial<GetUpdatedMessagesRequest>>) {
            const newState = {
                ...state.messagesPaginationState,
                ...action.payload
            } as GetUpdatedMessagesRequest
            state.messagesPaginationState = newState
        },
        resetSearchOperatorByCriterionRelatedData(state) {
            state.searchOperatorByCriterionPrevArgs = { ...initialState.searchOperatorByCriterionPrevArgs }
            state.searchOperatorByCriterionResponse = []
        },
        openTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = true
        },
        closeTopicsSidebar(state) {
            state.topics.isTopicsSidebarOpened = false
            const { treeData } = state.topics
            const { items, rootId } = treeData
            const treeDataIds = Object.keys(items).filter(itemId => itemId !== rootId)

            treeDataIds.forEach(topicId => (items[topicId].data.isSelected = false))
        },
        openKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = true
        },
        closeKnowledgeBaseSidebar(state) {
            state.knowledgeBase.isKnowledgeBaseSidebarOpened = false
        },
        openFastRepliesSidebar(state) {
            state.fastReplies.isFastRepliesSidebarOpened = true
        },
        closeFastRepliesSidebar(state) {
            state.fastReplies.isFastRepliesSidebarOpened = false
        },
        openArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = true
        },
        closeArticleSidebar(state) {
            state.knowledgeBase.isArticleSidebarOpened = false
        },
        selectAndExpandDialogTopic(state, action: PayloadAction<DialogTopicsTreeItem>) {
            const { treeData } = state.topics
            const { items, rootId } = treeData

            const treeDataIds = Object.keys(items).filter(itemId => itemId !== rootId)
            const selectedIds = getListOfSelectedIds(treeData, action.payload)

            treeDataIds.forEach(topicId => {
                const topic = items[topicId]
                if (selectedIds.includes(topicId)) {
                    topic.data.isSelected = true
                    topic.isExpanded = topicId === action.payload.id ? !topic.isExpanded : true
                } else {
                    topic.data.isSelected = false
                }
            })
        },
        clearMessageInput(state) {
            state.inputMessage = initialState.inputMessage
        },
        setMessageInput(state, action: PayloadAction<string>) {
            state.inputMessage = action.payload
        },
        createDialog(state) {
            state.dialogCreate.creatingDialog = true
            state.dialogCreate.isChannelSelectSidebarOpened = true
        },
        finishCreatingDialog(state) {
            state.dialogCreate.creatingDialog = false
            state.dialogCreate.isCreatingClientSidebarOpened = false
            state.dialogCreate.isChannelSelectSidebarOpened = false
            state.dialogCreate.dialogCreateOutgoingData = initialState.dialogCreate.dialogCreateOutgoingData
        },
        openCreateClientSidebar(state) {
            state.dialogCreate.isCreatingClientSidebarOpened = true
        },
        closeCreateClientSidebar(state) {
            state.dialogCreate.isCreatingClientSidebarOpened = false
        },
        openChannelSelectSidebar(state) {
            state.dialogCreate.isChannelSelectSidebarOpened = true
        },
        closeChannelSelectSidebar(state) {
            state.dialogCreate.isChannelSelectSidebarOpened = false
        },
        setDialogOutgoingData(state, action: PayloadAction<DialogOutgoingData | null>) {
            state.dialogCreate.dialogCreateOutgoingData = action.payload
        },
        setLastMessageChannel(state, action: PayloadAction<SimpleChannelWithUserId>) {
            state.lastMessageChannel = action.payload
        },
        setDraftedInput(state, action: PayloadAction<string>) {
            state.inputDrafted = action.payload
        },
        setEditMessageMode(state, action: PayloadAction<EditMessageData>) {
            state.inputEditMessageData = action.payload
        },
        offEditMessageMode(state) {
            state.inputEditMessageData = undefined
        },
        setInputMaximized(state, action: PayloadAction<boolean>) {
            state.isInputMaximized = action.payload
        },
        setRMECommands(state, action: PayloadAction<ContentEditorCommands>) {
            state.RMECommands = action.payload
        },
        setDialogEmailData(state, action: PayloadAction<DialogEmailData>) {
            state.dialogEmailData = action.payload
        },
        clearDialogEmailData(state) {
            state.dialogEmailData = initialState.dialogEmailData
        },
        setForceReadonly(state, action: PayloadAction<boolean>) {
            state.forceReadonly = action.payload
        },
        setCurrentOmniUserId(state, action: PayloadAction<string>) {
            state.currentOmniUserId = action.payload
        },
        setSearchData(state, action: PayloadAction<DialogBadge[]>) {
            state.searchData = action.payload
        },
        toggleVideoCallScreen(state) {
            state.videoCallScreenEnabled = !state.videoCallScreenEnabled
        },
        setVideoCallState(state, action: PayloadAction<Partial<VideoCallState>>) {
            for (const [key, value] of Object.entries(action.payload)) {
                // @ts-ignore
                state.videoCallState[key] = value
            }
        },
        toggleCollapsedVideoCallState(state) {
            state.videoCallState.isCollapsed = !state.videoCallState.isCollapsed
        }
    },
    extraReducers: builder => {
        if (!dialogsApi?.endpoints) {
            return
        }

        builder
            .addCase(resetReducerState, () => {
                return initialState
            })
            .addMatcher(
                action => action.type.endsWith("setDraftedInput"),
                (state, action) => {
                    const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId

                    if (!draftEntityId) {
                        return
                    }

                    if (action.payload) {
                        dialogDraftsLocalStoreDriver.insert(draftEntityId, action.payload)
                    } else {
                        dialogDraftsLocalStoreDriver.remove(draftEntityId)
                    }
                }
            )
            .addMatcher(dialogsApi.endpoints.searchOperatorByCriterion.matchFulfilled, (state, rawData) => {
                const originalArgs = rawData.meta.arg.originalArgs

                state.searchOperatorByCriterionResponse = rawData.payload

                state.searchOperatorByCriterionPrevArgs = {
                    ...state.searchOperatorByCriterionPrevArgs,
                    ...originalArgs
                }
            })
            .addMatcher(dialogsApi.endpoints.getDialogTopics.matchFulfilled, (state, { payload, ...p }) => {
                state.topics.treeData = convertDialogTopicsToTree(payload)
            })
    }
})

export default dialogs.reducer
export const { actions } = dialogs
