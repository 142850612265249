import { Dispatch } from "../../utility/common/storeHelper"
import { actions } from "./slice"
import { dialogDraftsLocalStoreDriver, useLazyGetDialogTopicsQuery } from "../../api/controllers/dialogs"
import store from "../store"
import { scrollDownMessageInput } from "../../utility/dialogs/scrollDownMessageInput"
import trim from "lodash/trim"
import { MESSAGES_PER_PAGE } from "../../utility/dialogs/constant"

export function backToDialogList() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
    }
}

export function clearDialogsState() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setSearchCriterion(""))
    }
}

export function createDialog() {
    return (dispatch: Dispatch) => {
        dispatch(actions.unsetCurrentDialogId())
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setCurrentOmniUserId(""))
        dispatch(actions.setDialogOutgoingData(null))
        dispatch(actions.createDialog())
    }
}

export const closeKnowledgeBaseSidebar = () => {
    return (dispatch: Dispatch) => {
        dispatch(actions.closeArticleSidebar())
        dispatch(actions.closeKnowledgeBaseSidebar())
    }
}

export const setMessageInputText = (text: string) => {
    return (dispatch: Dispatch) => {
        dispatch(actions.setMessageInput(text))
        dispatch(actions.setDraftedInput(text))
    }
}

export const addToMessageInputText = (text: string, isAddToStart = false) => {
    return (dispatch: Dispatch) => {
        const state = store.getState().dialogs

        const draftEntityId = state.selectedDialogId ?? state.selectedOperatorClientId ?? ""
        const currentInputText = dialogDraftsLocalStoreDriver.get(draftEntityId)
        const filteredText = trim(text, "\\\n")

        const pasteText = currentInputText
            ? isAddToStart
                ? `${filteredText}\n\n ${currentInputText}`
                : `${currentInputText}\n\n ${filteredText}`
            : filteredText

        dispatch(setMessageInputText(pasteText))

        // Without timeout, it doesn't work in empty input
        setTimeout(() => {
            if (!isAddToStart) {
                scrollDownMessageInput()
            }
        }, 0)
    }
}

export const switchToDialog =
    (
        dialogId: string,
        omniUserId: string,
        projectId: string,
        getTopics: ReturnType<typeof useLazyGetDialogTopicsQuery>[0]
    ) =>
    (dispatch: Dispatch) => {
        const storedMessage = dialogDraftsLocalStoreDriver.get(dialogId)
        dispatch(actions.setMessageInput(storedMessage || ""))
        dispatch(actions.unsetCurrentOperatorClientId())
        dispatch(actions.setCurrentDialogId(dialogId))
        dispatch(
            actions.setMessagesPaginationState({
                OmniUserId: omniUserId,
                StartTime: 0,
                Count: MESSAGES_PER_PAGE,
                FromTodayDialogs: true,
                ProjectId: projectId
            })
        )
        getTopics({
            ProjectId: projectId
        })
    }
